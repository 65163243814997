<template>
  <div class="mail_detail_inner" v-if="currentIndex >= 0">
    <div class="pagination">
      <span class="total">{{displayIndex + 1}} of {{total}}</span>
      <img class="chevron" :class="{disabled: !needPrev}" src="@/assets/images/icons/chevron_left_gray600.svg" @click="prev">
      <img class="chevron" :class="{disabled: !needNext}" src="@/assets/images/icons/chevron_right_gray600.svg" @click="next">
    </div>
    <header>
      <div class="subject">{{item.processMailTitle}}</div>
      <div class="flex">
        <div class="date">Send Date<span>{{item.noticeDate | dateTimeFormat}}</span></div>
        <a v-if="item.pdfPath" class="download_pdf" @click.prevent="pdfDownload" tabindex="-1" download>{{item.pdfPath | fileName}}</a>
      </div>

    </header>
    <div class="to">
      <div>To</div>
      <span v-for="destination in item.destination.slice(0, 200)" :key="destination.userIdTo"><figure class="avatar table"><img :src="destination.userIconTo"></figure>{{destination.userNameTo}}</span>
    </div>
    <div class="contents" v-html="sanitize" />
    <footer>
      <table>
        <tr>
          <td>Trading/Flow ID</td>
          <td>{{getTradingFlowId(item.tradingId, item.tradingFlowId)}}</td>
        </tr>
        <tr>
          <td>Trading Name</td>
          <td>{{item.tradingName}}</td>
        </tr>
        <tr>
          <td>Flow Name</td>
          <td>{{item.tradingManageName}}</td>
        </tr>
        <tr>
          <td>Invoice No</td>
          <td>{{item.invoiceNo}}</td>
        </tr>
        <tr>
          <td>Process</td>
          <td v-if="getProcessPagePrams()"><router-link :to="getProcessPagePrams()" tabindex="-1">{{item.processName}}</router-link></td>
          <td v-else><a @click="close" tabindex="-1">{{item.processName}}</a></td>
        </tr>
        <tr>
          <td>From - To</td>
          <td>
            <div class="from_to">
              <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                <div slot="content">{{item.from.fromCompanyName + ' ' + item.from.fromSectionName}}</div>
                <span><figure class="avatar table"><img :src="item.from.fromCompanyIcon"></figure><span style="display: block;max-width: 74px;overflow: hidden;white-space:nowrap;text-overflow: ellipsis;">{{item.from.fromCompanyShortName}}</span></span>
              </el-tooltip>
              <span style="margin: 0 12px">-</span>
              <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                <div slot="content">{{item.to.toCompanyName + ' ' + item.to.toSectionName}}</div>
                <span><figure class="avatar table"><img :src="item.to.toCompanyIcon"></figure><span style="display: block;max-width: 74px;overflow: hidden;white-space:nowrap;text-overflow: ellipsis;">{{item.to.toCompanyShortName}}</span></span>
              </el-tooltip>
            </div>
          </td>
        </tr>
      </table>
    </footer>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { INITIAL_VIEW_FLG } from 'lib-tw-common';

export default {
  name: 'TwDrawerInner',
  props: {
    noticeHistoryId: String,
    processId: String,
    entityId: String,
    processSeq: [String, Number],
    processTrx: [String, Number],
  },
  data() {
    return {
      items: null,
      totalCount: null,
      offset: null,
    };
  },
  computed: {
    total() {
      return this.totalCount || this.items.length;
    },
    currentIndex() {
      return _.findIndex(this.items, {noticeHistoryId: this.noticeHistoryId});
    },
    displayIndex() {
      if (this.processId) {
        return this.currentIndex;
      }
      return _.isNumber(this.offset) ? (this.offset + 1) : this.currentIndex;
    },
    needPrev() {
      return this.currentIndex > 0;
    },
    needNext() {
      return this.currentIndex < (this.items.length - 1);
      // return this.displayIndex < (this.totalCount ? (this.totalCount - 1) : (this.items.length - 1));
    },
    item() {
      return _.find(this.items, {noticeHistoryId: this.noticeHistoryId});
      // return this.items[this.currentIndex];
    },
    sanitize() {
      return this.$sanitize(this.item.processMailBody).replace(/\n/g, '<br>');
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (this.$route.name === "MailDetail") {
      next();
      this.fetch();
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      const q = this.$route.query;
      const sort = q.sort && q.target ? {
        sortType: _.isArray(q.sort) ? q.sort : [q.sort],
        target: _.isArray(q.target) ? q.target : [q.target],
      } : undefined;
      this.offset = parseInt(q.offset);
      if (_.isNaN(this.offset)) {
        this.offset = -1;
      }
      const params = this.processId ? {
        // bff_ms_2 送信履歴一覧検索(プロセス詳細用)BFF
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/email-transmission-histories/search/{entityId}/{processId}/{processSeq}',
          path: {
            entityId: this.entityId,
            processId: this.processId,
            processSeq: parseInt(this.processSeq)
          },
          query: {
            processTrx: this.processTrx ? parseInt(this.processTrx) : undefined
          }
        }
      } : {
        // bff_ms_1 メール送信履歴一覧検索BFF
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/email-transmission-histories/search',
          query: _.isEmpty(q) ? {
            noticeHistoryId: this.noticeHistoryId
          } : {
            initialFlag: INITIAL_VIEW_FLG.OFF, //q.initial ? parseInt(q.initial) : INITIAL_VIEW_FLG.OFF,
            limit: 3,
            offset: q.offset ? Math.max(q.offset, 0) : 0,
            sendStartDate: q.start,
            sendEndDate: q.end,
            mailSubject: q.subject,
            sort: sort,
          }
        }
      };

      $api.request(params)
      .then(res => {
        this.items = res.emailTransmissionHistories;
        this.totalCount = res.totalCount;
        if (!this.item) {
          this.$router.replace('/404/');
        }
      })
      .catch(err => {
        this.items = [];
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    prev() {
      if (this.$route.name === "MailDetail" || this.$route.name === "MailDetailFull") {
        this.$router.push({
          name: this.$route.name,
          params: { noticeHistoryId: this.items[this.currentIndex - 1].noticeHistoryId },
          query: {
            ...this.$route.query,
            offset: this.offset - 1,
          },
        });
      } else if (this.$route.name === "MailDetailFullProcess") {
        this.$router.push({
          name: this.$route.name,
          params: { noticeHistoryId: this.items[this.currentIndex - 1].noticeHistoryId, processId: this.processId, entityId: this.entityId, processSeq: this.processSeq },
          query: { processTrx: this.processTrx }
        });
      } else {
        this.$emit('set-notice-history-id', this.items[this.currentIndex - 1].noticeHistoryId);
      }
    },
    next() {
      if (this.$route.name === "MailDetail" || this.$route.name === "MailDetailFull") {
        this.$router.push({
          name: this.$route.name,
          params: { noticeHistoryId: this.items[this.currentIndex + 1].noticeHistoryId },
          query: {
            ...this.$route.query,
            offset: this.offset + 1,
          },
        });
      } else if (this.$route.name === "MailDetailFullProcess") {
        this.$router.push({
          name: this.$route.name,
          params: { noticeHistoryId: this.items[this.currentIndex + 1].noticeHistoryId, processId: this.processId, entityId: this.entityId, processSeq: this.processSeq },
          query: { processTrx: this.processTrx }
        });
      } else {
        this.$emit('set-notice-history-id', this.items[this.currentIndex + 1].noticeHistoryId);
      }
    },
    // プロセス詳細へのリンクを返却します
    getProcessPagePrams() {
      if (this.processId && this.$route.name !== "MailDetailFullProcess") {
        return false;
      }
      return {
        name: `Process${_.capitalize(this.item.processId.replace(/\d/g, ''))}`,
        params: { entityId: this.item.entityId, processSeq: this.item.processSeq, _processId: this.item.processId.toLowerCase() },
      }
    },
    pdfDownload() {
      const fileName = this.$options.filters.fileName(this.item.pdfPath);
      $api
      .pdfDownload(this.item.pdfPath, fileName)
      .then(() => {
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    close() {
      this.$store.commit('SET_DRAWER', false);
    },
    getTradingFlowId(tradingId, flowId) {
      return `${tradingId ? tradingId + '/' : ''}${flowId || ''}`;
    },
  }
}
</script>

<style lang="scss" scoped>
  .mail_detail_inner {
    width: 682px;

    .pagination {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid $color_gray_300;

      .total {
        font-size: 12px;
        line-height: 18px;
        color: $color_gray_600;
        margin-right: 40px;
      }

      img.chevron {
        display: block;
        width: 20px;
        height: 20px;
        margin-left: 40px;
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &:hover {
          opacity: .5;
        }
      }
    }

    header {
      padding: 10px 0;
      border-bottom: 1px solid $color_gray_300;

      .subject {
        font-weight: bold;
        font-size: 24.5px;
        line-height: 40px;
        color: $color_black;
        margin-bottom: 11px;
        word-break: break-word;
      }

      .flex {
        display: flex;
        justify-content: space-between;
      }

      .date {
        font-size: 12px;
        line-height: 18px;
        color: $color_gray_600;
        flex-shrink: 0;
        margin-right: 20px;

        span {
          margin-left: 8px;
        }
      }

      a.download_pdf {
        display: inline-flex;
        padding: 0 2px 0 28px;
        background: url(../../assets/images/icons/icon_file_pdf.png) no-repeat 0 -3px;
        background-size: 24px auto;
        text-decoration: none;
        font-size: 12px;
        line-height: 18px;
        color: $color_dark_blue;
        cursor: pointer;

      }
    }

    .to {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 22px 0;
      font-size: 14px;
      line-height: 20px;
      color: $color_black;
      border-bottom: 1px solid $color_gray_300;
      text-indent: -18px;
      padding-left: 18px;

      span {
        margin: 2px 0 2px 12px;
        text-indent: 0;
      }
    }

    .contents {
      padding: 24px 0;
      font-size: 14px;
      line-height: 20px;
      color: $color_gray_800;
      border-bottom: 1px solid $color_gray_300;
    }

    footer {
      padding: 24px 0;
      border-bottom: 1px solid $color_gray_300;

      table {
        width: 100%;
        border-collapse: collapse;
        padding: 0;
      }

      td {
        font-size: 14px;
        line-height: 20px;
        vertical-align: top;
        padding: 0;
        word-break: break-word;

        a {
          text-decoration: none;
          color: #5185C5;
        }
      }

      tr > td:first-child {
        min-width: 128px;
        color: #5E5873;
        padding: 0 12px 16px 0;
      }

      .from_to {
        display: flex;
        align-items: center;
      }
    }

    .to span, .from_to span {
      display: flex;
      align-items:center;
      font-size: 12px;
      line-height: 20px;
      color: $color_black;

      figure.avatar.table {
        margin-right: 8px;
      }
    }
  }

</style>
