<template>
  <div class="mail_detail">
    <tw-mail-detail-inner ref="inner" :noticeHistoryId="noticeHistoryId" :processId="processId" :entityId="entityId" :processSeq="processSeq" :processTrx="processTrx" />
  </div>
</template>

<script>
import TwMailDetailInner from '@/components/templates/TwMailDetailInner';

export default {
  name: 'MailDetail',
  props: {
    noticeHistoryId: String,
    processId: String,
    entityId: String,
    processSeq: [String, Number],
    processTrx: [String, Number],
  },
  components: {
    TwMailDetailInner,
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.$refs.inner.fetch();
  },
};
</script>

<style lang="scss" scoped>
  .mail_detail {
    position: relative;
    width: 900px;
    height: calc(100% - 80px);
    margin: 0 auto;
    background: $color_white;
    border-radius: 6px;
    @include card_shadow;
    padding: 40px 108px;
    overflow-y: auto;
  }
</style>
