import { render, staticRenderFns } from "./TwMailDetailInner.vue?vue&type=template&id=026c6304&scoped=true&"
import script from "./TwMailDetailInner.vue?vue&type=script&lang=js&"
export * from "./TwMailDetailInner.vue?vue&type=script&lang=js&"
import style0 from "./TwMailDetailInner.vue?vue&type=style&index=0&id=026c6304&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026c6304",
  null
  
)

export default component.exports